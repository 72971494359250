import { useMemo } from 'react'
import { format } from 'date-fns'
import { DashboardStructureType, DateType } from '../contexts/dashboardContext'
import { WHOLE_GERMANY_ID } from '../pages/DashboardPage/constants'
import { getAverageValues } from '../utils/dashboardUtils'
import {
  useGermanySummaryQuery,
  useOfftimesQuery,
  usePharmacyClustersQuery,
  useStateHolidaysClusterQuery,
  useAllProjectGoalsQuery,
  useQuartersQuery,
  useAllGoalsQuery,
} from './graphql'
import { useOfftimesSummary } from './useOfftimesSummary'
import { useWorkingDays } from './useWorkingDays'
import { ClusterQuarterProjectGoal } from '@aposphaere/core-kit'

export const useGermanyDashboardSummary = (clusterSelected: string, dates: DateType) => {
  const enabled = clusterSelected === WHOLE_GERMANY_ID
  const date = { from: format(dates.startDate, 'yyyy-MM-dd'), to: format(dates.endDate, 'yyyy-MM-dd') }

  const { data: summaryCardsDataRaw, isLoading: summaryCardsDataIsLoading } = useGermanySummaryQuery({ enabled, date })
  const { data: pharmacyClusters, isLoading: clustersIsLoading } = usePharmacyClustersQuery()
  const { data: offtimes, isLoading: isOfftimesLoading } = useOfftimesQuery({ enabled, date, clusterId: clusterSelected })
  const { data: publicHolidaysData, isLoading: isPublicHolidaysLoading } = useStateHolidaysClusterQuery({ enabled, date })

  const {
    offtimesUntilTodayCount,
    futureOfftimesCount,
    offtimeCountsPerType: { homeOfficeCount, sickCount, meetingsCount, vacationCount, vacantCount, privateCount },
  } = useOfftimesSummary(offtimes)

  const appointmentsCompleted = summaryCardsDataRaw?.appointments_completed ?? 0
  const appointmentsBooked = summaryCardsDataRaw?.appointments_booked ?? 0
  const appointmentsCancelled = summaryCardsDataRaw?.canceled_in_range ?? 0
  const pastCancelledTrainings = summaryCardsDataRaw?.canceled_till_today ?? 0
  const visitsCompleted = summaryCardsDataRaw?.visits_completed ?? 0
  const visitsBooked = summaryCardsDataRaw?.visits_booked ?? 0
  const pharmaciesCount = summaryCardsDataRaw?.pharmacies_count ?? 0
  const bookedPharmaciesCount = summaryCardsDataRaw?.pharmacies_with_appointment ?? 0
  const trainedPharmaciesCount = summaryCardsDataRaw?.pharmacies_completed ?? 0
  const bookedForFuturePharmaciesCount = summaryCardsDataRaw?.pharmacies_booked ?? 0
  const pharmaciesWithCancelledAppointmentsCount = summaryCardsDataRaw?.pharmacies_canceled ?? 0
  const pharmaciesAnsweredQuestion = summaryCardsDataRaw?.pharmacies_answered_question ?? 0
  const pharmaciesWithMultipleAppointmentsCount =
    pharmaciesWithCancelledAppointmentsCount + trainedPharmaciesCount + bookedForFuturePharmaciesCount - bookedPharmaciesCount
  const pastTrainingSets = summaryCardsDataRaw?.trainings_count ?? 0
  const pastRepeatedTrainingSets = summaryCardsDataRaw?.trainigns_multiple ?? 0
  const bookedSlots = summaryCardsDataRaw?.slots_booked ?? 0
  const completedSlots = summaryCardsDataRaw?.slots_completed ?? 0
  const totalSlots = bookedSlots + completedSlots
  const isLoading = summaryCardsDataIsLoading || clustersIsLoading || isOfftimesLoading || isPublicHolidaysLoading
  const clustersCount = pharmacyClusters?.filter((cluster) => !!cluster.agent).length

  const { totalDaysInField, daysInFieldUntilToday, daysInFieldLeft, totalWorkingDays } = useWorkingDays({
    dates,
    clustersCount,
    publicHolidaysData: publicHolidaysData ?? [],
    offtimesUntilTodayCount,
    futureOfftimesCount,
  })

  const { data: allGoals } = useAllProjectGoalsQuery({ date, enabled })

  const summarizeGoals = (allGoals: ClusterQuarterProjectGoal[] | undefined) => {
    const summary: Record<number, number> = {}

    allGoals?.forEach((item) => {
      const projectId = item.project_id
      const goal = item.adjusted_goal.goal

      if (summary[projectId]) {
        summary[projectId] += goal
      } else {
        summary[projectId] = goal
      }
    })

    return summary
  }
  const summarizedGoals = summarizeGoals(allGoals)

  const summarizeWorkdays = (allGoals: ClusterQuarterProjectGoal[] | undefined) => {
    const summary: Record<number, number> = {}

    allGoals?.forEach((item) => {
      const projectId = item.project_id
      const workdays = item.adjusted_goal.workdays

      if (summary[projectId]) {
        summary[projectId] += workdays
      } else {
        summary[projectId] = workdays
      }
    })

    return summary
  }
  const summarizedWorkdays = summarizeWorkdays(allGoals)

  const summaryPerProjectData = useMemo(() => {
    return (
      summaryCardsDataRaw?.per_project.map((project) => {
        const goalPerDateRange = summarizedGoals[project.project.id] || 0
        const totalTrainingCount = (project.appointments_future ?? 0) + (project.pharmacies_completed ?? 0)
        const gapTrainingCount = +goalPerDateRange - totalTrainingCount
        const bookedTimePerProject = (project.project.booked_time_in_seconds / 60).toFixed()
        return {
          trainingCompletedCount: project.slot_count,
          id: project.project.id.toString(),
          name: project.project.name,
          projectPharmacyCount: project.pharmacies_potential,
          averageDuration: project.average_duration,
          pharmaciesReceivedTrainingCount: project.pharmacies_completed,
          bookedFromTomorrow: project.appointments_future,
          cancelledCount: project.appointments_canceled,
          consecutiveTrainingCount: project.pharmacies_multiple,
          participantCount: project.participant_count,
          totalTrainingCount: (project.pharmacies_completed ?? 0) + (project.appointments_future ?? 0),
          projectGoal: +goalPerDateRange,
          gapTrainingCount: gapTrainingCount,
          averageTotalTrainingCount:
            summarizedWorkdays[project.project.id] > 0 ? +(totalTrainingCount / summarizedWorkdays[project.project.id]).toFixed(2) : 0,
          averageTrainingGoal:
            summarizedWorkdays[project.project.id] > 0 ? (+goalPerDateRange / summarizedWorkdays[project.project.id]).toFixed(2) : 0,
          averageGapTrainingCount:
            summarizedWorkdays[project.project.id] > 0 ? (gapTrainingCount / summarizedWorkdays[project.project.id]).toFixed(2) : 0,
          goalAchievement: +goalPerDateRange > 0 ? ((totalTrainingCount / +goalPerDateRange) * 100).toFixed() : 0,
          bookedTimePerProject,
        }
      }) ?? []
    )
  }, [summaryCardsDataRaw, allGoals, clusterSelected, summarizedGoals, dates])

  const dataForGraph = useMemo(
    () =>
      summaryCardsDataRaw?.per_day.map((entry) => ({
        label: entry.date,
        cancelledCount: entry.appointments_canceled,
        bookedCount: entry.appointments_booked,
        visitCount: entry.visits_booked,
        trainingDoneCount: entry.appointments_completed,
      })) ?? [],
    [summaryCardsDataRaw],
  )

  const totalParticipantCount = summaryCardsDataRaw?.participant_count ?? 0
  const totalAppointmentsCount = appointmentsCompleted + appointmentsBooked + appointmentsCancelled
  const averageAppointmentCount = getAverageValues(totalAppointmentsCount, totalDaysInField)
  const averageTrainingsPerDay = getAverageValues(pastTrainingSets, daysInFieldUntilToday)
  const averageSlotsPerAppointment = getAverageValues(completedSlots + bookedSlots, appointmentsCompleted + appointmentsBooked)
  const averageSlotsPerDay = getAverageValues(completedSlots + bookedSlots, totalDaysInField)
  const averageParticipantsPerAppointment = getAverageValues(totalParticipantCount, appointmentsCompleted)
  const trainingsQuote =
    trainedPharmaciesCount + pastCancelledTrainings > 0 ? (trainedPharmaciesCount / (trainedPharmaciesCount + pastCancelledTrainings)) * 100 : 0
  const exitQuestionResponseRate = trainedPharmaciesCount > 0 ? (pharmaciesAnsweredQuestion / trainedPharmaciesCount) * 100 : 0
  const repeatedTrainigsCount = pastRepeatedTrainingSets
  return {
    [DashboardStructureType.SUMMARY_CARDS]: {
      appointmentsCompleted,
      appointmentsBooked,
      visitsCompleted,
      visitsBooked,
      averageAppointmentCount,
      bookedPharmaciesCount,
      pharmaciesCount,
      trainedPharmaciesCount,
      bookedForFuturePharmaciesCount,
      pharmaciesWithMultipleAppointmentsCount,
      pharmaciesWithCancelledAppointmentsCount,
      trainingsQuote,
      pastTrainingSets,
      pastRepeatedTrainingSets,
      repeatedTrainigsCount,
      averageTrainingsPerDay,
      totalSlots,
      bookedSlots,
      completedSlots,
      averageSlotsPerAppointment,
      averageSlotsPerDay,
      totalParticipantCount,
      averageParticipantsPerAppointment,
      homeOfficeCount,
      vacationCount,
      privateCount,
      meetingsCount,
      sickCount,
      vacantCount,
      daysInFieldLeft,
      totalWorkingDays,
      totalDaysInField,
      exitQuestionResponseRate,
    },
    isLoading,
    [DashboardStructureType.GRAPH]: dataForGraph,
    [DashboardStructureType.SUMMARY_PER_PROJECT]: summaryPerProjectData,
  }
}
